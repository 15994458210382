import request from "@/utils/request";

/**获取短信验证码
 * itype 1:登录短信验证码,2:绑定手机号码,3:更换手机号码
 * */
export function verificationCode(mobile, itype) {
    return request({
        url: '/front/sms/doSend',
        method: 'post',
        data: {
            mobile,
            itype
        }
    })
}

/**
 * 用户登录
 * mobile：手机号
 * verCode：验证码
 * */
export function login(mobile, verCode) {
    return request({
        url: '/admin/user/login/doLogin',
        method: 'post',
        data: {
            mobile,
            verCode
        }
    })
}

/**
 * 获取用户列表
 * */
export function userList(data) {
    return request({
        url: '/admin/user/search',
        method: 'post',
        data
    })
}

/**
 * 添加用户
 * */
export function userAdd(data) {
    return request({
        url: '/admin/user/doAdd',
        method: 'post',
        data
    })
}

/**
 * 更新用户
 * */
export function userUpdate(data) {
    return request({
        url: '/admin/user/doUpdate',
        method: 'post',
        data
    })
}

/**
 * 用户详情
 * */
export function userDetail(id) {
    return request({
        url: '/admin/user/detail',
        method: 'post',
        data: {id}
    })
}