import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "myPagination"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { Search } from "@element-plus/icons-vue";
import { onMounted, reactive, ref } from "vue";
import { carRentalOrderList, refund } from "@/api/order";
import CarRentalOrderDetails from "@/views/order/page/carRentalOrderDetails.vue";

import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'carRentalOrder',
  setup(__props) {
    const searchData = reactive({
      "mobile": "",
      "cardId": "",
      "trueName": "",
      "payStatus": "",
      "validStatus": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      carRentalOrderList(searchData.valueOf()).then(res => {
        tableData.value = res.data.orderRentDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    onMounted(() => {
      search();
    });
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };

    //打开详情
    const drawerDetails = ref(false);
    const isOpenDrawer = () => {
      drawerDetails.value = !drawerDetails.value;
    };
    let id = ref("");
    const openDetails = data => {
      id.value = data;
      isOpenDrawer();
    };
    const applicationRefund = id => {
      ElMessageBox.confirm('确定要同意此账单的退款申请吗?', '退款提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        refund(id).then(res => {
          if (res.result === 0) {
            ElMessage({
              showClose: true,
              message: res.msg,
              type: 'success'
            });
            search();
          } else {
            ElMessage({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "租车订单"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.mobile,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.mobile = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.cardId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.cardId = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.trueName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.trueName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.payStatus,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.payStatus = $event),
        placeholder: "付款状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "付款状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "未付款",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "扣款中",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "扣款成功",
          value: 2
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 3,
          label: "扣款失败",
          value: 3
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 4,
          label: "关闭",
          value: 4
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_select, {
        modelValue: searchData.validStatus,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => searchData.validStatus = $event),
        placeholder: "生效状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "生效状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "未生效",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "已生效",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "会员姓名",
          prop: "trueName"
        }), _createVNode(_component_el_table_column, {
          label: "手机号",
          prop: "mobile"
        }), _createVNode(_component_el_table_column, {
          label: "商品名称",
          prop: "goodName"
        }), _createVNode(_component_el_table_column, {
          label: "商品订单号",
          prop: "orderNumber"
        }), _createVNode(_component_el_table_column, {
          label: "租车订单号",
          prop: "outTradeNo"
        }), _createVNode(_component_el_table_column, {
          width: "60",
          label: "期数",
          prop: "rentTermIndex"
        }), _createVNode(_component_el_table_column, {
          label: "租金",
          prop: "rentPrice"
        }), _createVNode(_component_el_table_column, {
          label: "起始日期",
          prop: "rentStartTime"
        }), _createVNode(_component_el_table_column, {
          label: "终止日期",
          prop: "rentEndTime"
        }), _createVNode(_component_el_table_column, {
          label: "支付状态",
          prop: "strPayStatus"
        }), _createVNode(_component_el_table_column, {
          label: "账单时间",
          prop: "deductionTime"
        }), _createVNode(_component_el_table_column, {
          label: "生效状态",
          prop: "strRefundStatus"
        }, {
          default: _withCtx(scope => [scope.row.validStatus === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_2, "未生效")) : _createCommentVNode("", true), scope.row.validStatus === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_3, "已生效")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "扣款时间",
          prop: "startPayTime"
        }), _createVNode(_component_el_table_column, {
          label: "门店名称",
          prop: "storeName"
        }), _createVNode(_component_el_table_column, {
          prop: "id,integer"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            onClick: $event => openDetails(scope.row.id),
            size: "small",
            type: "primary",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("详情")]),
            _: 2
          }, 1032, ["onClick"]), scope.row.refundButtonStatus === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: $event => applicationRefund(scope.row.id),
            size: "small",
            type: "warning",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode(" 确认退款 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_drawer, {
        modelValue: drawerDetails.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => drawerDetails.value = $event),
        title: "订单详情",
        size: "50%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [drawerDetails.value ? (_openBlock(), _createBlock(CarRentalOrderDetails, {
          key: 0,
          id: _unref(id)
        }, null, 8, ["id"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};