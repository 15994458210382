import store from "@/store";

// export const BASE_URL = "/api"//代理
// export const BASE_URL = 'https://test.car.kuaihuxing.com' // 测试环境
export const BASE_URL = 'https://car.kuaihuxing.com' // 正式环境


export const UPLOAD_FILE_URL = BASE_URL + "/admin/file/upload";

export const UPLOAD_FILE_HEADER = {Logintoken: store.getters.getToken};

