import request from "@/utils/request";

/**
 * 会员列表
 * */
export function memberList(data) {
    return request({
        url: '/admin/member/search',
        method: 'post',
        data
    })
}

/**
 * 会员详情
 * */
export function detail(id) {
    return request({
        url: '/admin/member/detail',
        method: 'post',
        data: {id}
    })
}



