import "core-js/modules/es.array.push.js";
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0c117d31"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 2
};
const _hoisted_5 = {
  class: "myPagination"
};
const _hoisted_6 = {
  class: "my-header"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1,
  style: {
    "color": "red"
  }
};
const _hoisted_9 = {
  key: 2
};
const _hoisted_10 = {
  key: 3
};
const _hoisted_11 = {
  key: 0
};
const _hoisted_12 = {
  key: 1
};
import PageHeaderComponents from "@/components/page-header.vue";
import { Edit, LocationInformation, Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import { cartype, lockUnlockCar, uploadingVehicle, vehicleList } from "@/api/vehicle";
import { UPLOAD_FILE_HEADER } from "@/utils/config";
import StoreAutocomplete from "@/components/store-autocomplete.vue";

import { ElMessage, ElLoading, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/loading/style/css';
import 'element-plus/es/components/message-box/style/css';
export default {
  __name: 'vehicleList',
  setup(__props) {
    const router = useRouter();
    const searchData = reactive({
      "assetCode": "",
      "carNumber": "",
      "bikeNo": "",
      "istatus": "",
      "carTypeId": "",
      "storeId": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      vehicleList(searchData.valueOf()).then(res => {
        tableData.value = res.data.carDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };
    const carTypeList = ref([]);
    onMounted(() => {
      cartype().then(res => {
        carTypeList.value = res.data;
      });
      search();
    });
    const openUpdate = id => {
      router.push({
        name: "vehicleUpdate",
        state: {
          id
        }
      });
    };
    const getStoreId = id => {
      searchData.valueOf().storeId = id;
    };
    const autocompleteChange = res => {
      if (res === "") {
        searchData.valueOf().storeId = "";
      }
    };
    const upload = ref("");
    const errorCarList = ref([]);
    const alertCloseCallback = () => {
      upload.value.clearFiles();
      isToLeadAlert.value = false;
      errorCarList.value = [];
    };
    let loading = null;
    const uploadingCarFiles = () => {
      if (errorCarList.value.length > 0) {
        ElMessage.error("请修改文件后重新上传");
        return;
      }
      loading = ElLoading.service({
        lock: true,
        text: '正在导入文件',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      upload.value.submit();
    };

    //导入文件后是否弹出
    const isToLeadAlert = ref(false);
    const toLeadFileName = ref("");
    const toLeadFileSize = ref("");
    const uploadChange = res => {
      isToLeadAlert.value = true;
      toLeadFileName.value = res.name;
      toLeadFileSize.value = "大小：" + (res.size / 1024 / 1024).toFixed(2) + "MB";
      const result = res.response;
      if (result !== undefined) {
        loading.close();
        if (result.result === 0) {
          if (result.data.batchImportStatus === 0) {
            ElMessage({
              message: result.msg,
              type: 'success'
            });
            alertCloseCallback();
            search();
          } else {
            errorCarList.value = result.data.carBatchImportDetailInfoList;
          }
        } else {
          ElMessage.error(result.msg);
          alertCloseCallback();
        }
      }
    };
    const lockCar = (id, lockStatus) => {
      let alertText = "";
      if (lockStatus === 0) {
        alertText = "确定解锁车辆吗？";
      } else if (lockStatus === 1) {
        alertText = "确定锁定车辆吗？";
      } else if (lockStatus === 2) {
        alertText = "确定设置车辆速度为25KM？";
      }
      ElMessageBox.confirm(alertText, '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        lockUnlockCar(id, lockStatus).then(res => {
          console.log(res);
          if (res.result === 0) {
            search();
            ElMessage({
              message: res.msg,
              type: 'success'
            });
          } else {
            ElMessage({
              message: res.msg,
              type: 'error'
            });
          }
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
      const _component_el_descriptions = _resolveComponent("el-descriptions");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "车辆表"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_upload, {
          ref_key: "upload",
          ref: upload,
          action: _unref(uploadingVehicle)(),
          headers: _unref(UPLOAD_FILE_HEADER),
          limit: 1,
          "on-change": uploadChange,
          "auto-upload": false,
          "show-file-list": false
        }, {
          trigger: _withCtx(() => [_createVNode(_component_el_button, {
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("导入")]),
            _: 1
          })]),
          _: 1
        }, 8, ["action", "headers"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.assetCode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.assetCode = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "车架号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.carNumber,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.carNumber = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "车牌号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.bikeNo,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.bikeNo = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "车辆编号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.istatus,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.istatus = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "可租用",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "租用中",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "下架",
          value: 2
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_select, {
        modelValue: searchData.carTypeId,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => searchData.carTypeId = $event),
        placeholder: "车型"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carTypeList.value, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.vname,
            value: item.id
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(StoreAutocomplete, {
        onAutocompleteChangeData: autocompleteChange,
        onAutocompleteSelectData: getStoreId
      }), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      }), _createVNode(_component_el_button, {
        plain: "",
        type: "warning",
        onClick: _cache[5] || (_cache[5] = $event => openUpdate(0))
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "车型",
          prop: "strCarTypeName"
        }), _createVNode(_component_el_table_column, {
          label: "车架号",
          prop: "assetCode"
        }), _createVNode(_component_el_table_column, {
          label: "车牌号",
          prop: "carNumber"
        }), _createVNode(_component_el_table_column, {
          label: "车辆编号",
          prop: "bikeNo"
        }), _createVNode(_component_el_table_column, {
          label: "入库时间",
          prop: "importDate"
        }), _createVNode(_component_el_table_column, {
          label: "车辆归属",
          prop: "storeName"
        }), _createVNode(_component_el_table_column, {
          label: "车辆状态",
          prop: "strIstatus"
        }), _createVNode(_component_el_table_column, {
          label: "车辆锁定状态",
          prop: "carLockStatus"
        }, {
          default: _withCtx(scope => [scope.row.carLockStatus === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_2, "正常")) : _createCommentVNode("", true), scope.row.carLockStatus === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_3, "已锁定")) : _createCommentVNode("", true), scope.row.carLockStatus === 2 ? (_openBlock(), _createElementBlock("span", _hoisted_4, "未知")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "速度状态描述",
          prop: "speedStatus"
        }), _createVNode(_component_el_table_column, {
          prop: "id,lockButton,unLockButton"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            icon: _unref(Edit),
            onClick: $event => openUpdate(scope.row.id),
            plain: "",
            size: "small",
            type: "primary"
          }, null, 8, ["icon", "onClick"]), scope.row.carBikeNoNewestPosInfo ? (_openBlock(), _createBlock(_component_el_popover, {
            key: 0,
            placement: "right",
            width: 400,
            trigger: "click"
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(LocationInformation),
              plain: "",
              size: "small",
              type: "warning"
            }, null, 8, ["icon"])]),
            default: _withCtx(() => [_createVNode(_component_el_descriptions, {
              title: scope.row.carBikeNoNewestPosStatus === 0 ? '车辆位置信息' : '',
              column: 2,
              size: "default",
              direction: "vertical"
            }, {
              default: _withCtx(() => [scope.row.carBikeNoNewestPosStatus === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" 车辆编号 ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.bikeNo), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" 最后一次同步时间 ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.lastSynTime), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" gdlat ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.gdlat), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" gdlng ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.gdlng), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" gpslat ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.gpslat), 1)]),
                _: 2
              }, 1024), _createVNode(_component_el_descriptions_item, null, {
                label: _withCtx(() => [_createTextVNode(" gpslng ")]),
                default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(scope.row.carBikeNoNewestPosInfo.gpslng), 1)]),
                _: 2
              }, 1024)], 64)) : (_openBlock(), _createBlock(_component_el_descriptions_item, {
                key: 1
              }, {
                default: _withCtx(() => [_createTextVNode(" 该车辆暂无位置信息~ ")]),
                _: 1
              }))]),
              _: 2
            }, 1032, ["title"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), scope.row.lockButton === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            onClick: $event => lockCar(scope.row.id, 1),
            size: "small",
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode(" 锁定 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.unLockButton === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            onClick: $event => lockCar(scope.row.id, 0),
            size: "small",
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode(" 解锁 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.spend25Button === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 3,
            onClick: $event => lockCar(scope.row.id, 2),
            size: "small",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode(" 速度25 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_dialog, {
        modelValue: isToLeadAlert.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => isToLeadAlert.value = $event),
        fullscreen: errorCarList.value.length > 0,
        "before-close": alertCloseCallback,
        "show-close": false,
        "close-on-click-modal": false
      }, {
        header: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_6, [errorCarList.value.length <= 0 ? (_openBlock(), _createElementBlock("h4", _hoisted_7, "确认要导入下面的文件吗？")) : _createCommentVNode("", true), errorCarList.value.length > 0 ? (_openBlock(), _createElementBlock("h4", _hoisted_8, "请检查上传的文件！")) : _createCommentVNode("", true), errorCarList.value.length <= 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_el_button, {
          onClick: uploadingCarFiles,
          type: "primary",
          plain: ""
        }, {
          default: _withCtx(() => [_createTextVNode("确认")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: close,
          type: "info",
          plain: ""
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 2
        }, 1032, ["onClick"])])) : _createCommentVNode("", true), errorCarList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_el_button, {
          onClick: close,
          type: "primary",
          plain: ""
        }, {
          default: _withCtx(() => [_createTextVNode("关闭")]),
          _: 2
        }, 1032, ["onClick"])])) : _createCommentVNode("", true)])]),
        default: _withCtx(() => [errorCarList.value.length <= 0 ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(toLeadFileName.value), 1)) : _createCommentVNode("", true), errorCarList.value.length <= 0 ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(toLeadFileSize.value), 1)) : _createCommentVNode("", true), errorCarList.value.length > 0 ? (_openBlock(), _createBlock(_component_el_table, {
          key: 2,
          data: errorCarList.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            property: "carTypeName",
            label: "车型"
          }), _createVNode(_component_el_table_column, {
            property: "assetCode",
            label: "车架号"
          }), _createVNode(_component_el_table_column, {
            property: "carNumber",
            label: "车牌号"
          }), _createVNode(_component_el_table_column, {
            property: "bikeNo",
            label: "车牌编号"
          }), _createVNode(_component_el_table_column, {
            property: "errorDesc",
            label: "错误信息"
          })]),
          _: 1
        }, 8, ["data"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue", "fullscreen"])], 64);
    };
  }
};