import {createStore} from 'vuex'
import {storage} from "@/model/storage";

export default createStore({
    state: {
        token: "",
        routerMenu: "",
        isLoadRouters: false,
    },
    getters: {
        /*
        * 获取登录 储存的token
        * */
        getToken: state => {
            state.token = storage.get('token')
            return state.token
        },
        /*
        * 获取登录储存的菜单
        * */
        getMenuList: state => {
            state.menuList = storage.get("menuList")
            return state.menuList
        },
        /*
       * 获取登陆状态
       * */
        getIsLoadRouters: state => {
            return state.isLoadRouters
        },
        getMenuPath: state => {
            state.menuPath = storage.get("menuPath");
            return state.menuPath
        },
    },
    mutations: {
        // 修改token，并将token存入sessionStorage
        setToken(state, token) {
            state.token = token;
            storage.set('token', token)
        },
        // 删除token
        delToken(state) {
            state.token = ''
            storage.remove('token')
        },
        setMenuPath(state, menuPath) {
            state.menuPath = menuPath;
            storage.set('menuPath', menuPath)
        },
        setMenuList: (state, menuList) => {
            state.menuList = menuList
            storage.set('menuList', menuList)
        },
        setIsLoadRouters: (state, isLoadRouters) => {
            state.isLoadRouters = isLoadRouters
        }
    },
    actions: {},
    modules: {}
})
