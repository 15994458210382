import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    // const debounce = (fn, delay) => {
    //   let timer = null;
    //   return function () {
    //     let context = this;
    //     let args = arguments;
    //     clearTimeout(timer);
    //     timer = setTimeout(function () {
    //       fn.apply(context, args);
    //     }, delay);
    //   }
    // }
    //
    // const _ResizeObserver = window.ResizeObserver;
    // window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    //   constructor(callback) {
    //     callback = debounce(callback, 0);
    //     super(callback);
    //   }
    // }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};