import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import { ref, watchEffect } from "vue";
import { carRentalOrderDetails } from "@/api/order";
export default {
  __name: 'carRentalOrderDetails',
  props: {
    id: String
  },
  setup(__props) {
    const props = __props;
    const formData = ref({});
    watchEffect(() => {
      if (props.id !== "") {
        carRentalOrderDetails(props.id).then(res => {
          formData.value = res.data;
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData.value,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "会员姓名",
          prop: "trueName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.trueName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.trueName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "mobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.mobile,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.mobile = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "商品名称",
          prop: "goodName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.goodName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.goodName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "证件号码",
          prop: "cardId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.cardId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.cardId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "租期开始时间",
          prop: "rentStartTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.rentStartTime,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.rentStartTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "租期截止时间",
          prop: "rentEndTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.rentEndTime,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.value.rentEndTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "期数",
          prop: "rentTermIndex"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.rentTermIndex,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.value.rentTermIndex = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "车辆归属门店",
          prop: "storeName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.storeName,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formData.value.storeName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "商品名称",
          prop: "goodName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.goodName,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formData.value.goodName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "扣款时间",
          prop: "deductionTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.deductionTime,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formData.value.deductionTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "租期状态",
          prop: "strPayStatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strPayStatus,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formData.value.strPayStatus = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "每期租金",
          prop: "rentDays"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.rentPrice,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formData.value.rentPrice = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "订单ID",
          prop: "orderId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.orderId,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => formData.value.orderId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "交易流水号",
          prop: "transactionId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.transactionId,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => formData.value.transactionId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "订单号",
          prop: "outTradeNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.outTradeNo,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => formData.value.outTradeNo = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "发起扣款时间",
          prop: "startPayTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.startPayTime,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => formData.value.startPayTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "会员ID",
          prop: "memberId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.memberId,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => formData.value.memberId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "门店ID",
          prop: "storeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.storeId,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => formData.value.storeId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "门店名称",
          prop: "storeName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.storeName,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => formData.value.storeName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};