import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, ref } from "vue";
import { detail } from "@/api/member";
export default {
  __name: 'update',
  setup(__props) {
    const formData = ref({});
    let id = null;
    onMounted(() => {
      id = history.state.id;
      detail(id).then(res => {
        console.log(res);
        formData.value = res.data;
      });
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PageHeaderComponents, {
        label: "会员详情"
      }), _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData.value,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "会员ID",
          prop: "id"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.id = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "小程序openId",
          prop: "vopenId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.vopenId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.vopenId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "名字",
          prop: "trueName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.trueName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.trueName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号",
          prop: "mobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.mobile,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.mobile = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "性别",
          prop: "strSex"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strSex,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.strSex = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "昵称",
          prop: "nickName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.nickName,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.value.nickName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证",
          prop: "carTypeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            title: "人像面",
            style: {
              "width": "100px"
            },
            "preview-src-list": [formData.value.cardImageUrl1],
            src: formData.value.cardImageUrl1
          }, null, 8, ["preview-src-list", "src"]), _createVNode(_component_el_image, {
            title: "国徽面",
            style: {
              "width": "100px"
            },
            "preview-src-list": [formData.value.cardImageUrl2],
            src: formData.value.cardImageUrl2
          }, null, 8, ["preview-src-list", "src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "人脸照片",
          prop: "carTypeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            title: "人脸照片",
            style: {
              "width": "100px"
            },
            "preview-src-list": [formData.value.faceUrl],
            src: formData.value.faceUrl
          }, null, 8, ["preview-src-list", "src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "头像",
          prop: "carTypeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            title: "头像",
            style: {
              "width": "100px"
            },
            "preview-src-list": [formData.value.headerImageUrl],
            src: formData.value.headerImageUrl
          }, null, 8, ["preview-src-list", "src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证号码",
          prop: "cardId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.cardId,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.value.cardId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "居民身份证地址",
          prop: "address"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.address,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formData.value.address = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "出生日期",
          prop: "birthDate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.birthDate,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formData.value.birthDate = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "证件有效期",
          prop: "validFrom,validTo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            value: formData.value.validFrom + ' - ' + formData.value.validTo
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "strIstatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strIstatus,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formData.value.strIstatus = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "注册时间",
          prop: "birthDate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strCreateTime,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formData.value.strCreateTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "绑定手机号码时间",
          prop: "birthDate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strBindMobileTime,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formData.value.strBindMobileTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "认证时间",
          prop: "birthDate"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strBindCardTime,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => formData.value.strBindCardTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};