import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "myPagination"
};
import { Edit, Search } from "@element-plus/icons-vue";
import { onMounted, reactive, ref } from "vue";
import PageHeaderComponents from "@/components/page-header.vue";
import { memberList } from "@/api/member";
import { useRouter } from "vue-router";
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const searchData = reactive({
      "mobile": "",
      "trueName": "",
      "cardId": "",
      "istatus": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      memberList(searchData.valueOf()).then(res => {
        tableData.value = res.data.memberDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    const openUpdate = id => {
      router.push({
        name: 'updateMember',
        state: {
          id
        }
      });
    };
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };
    onMounted(() => {
      search();
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "会员列表"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.trueName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.trueName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.mobile,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.mobile = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.cardId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.cardId = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.istatus,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.istatus = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "启用",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "禁用",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "会员ID",
          prop: "id"
        }), _createVNode(_component_el_table_column, {
          label: "名字",
          prop: "trueName"
        }), _createVNode(_component_el_table_column, {
          label: "手机号",
          prop: "mobile"
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          prop: "strIstatus"
        }), _createVNode(_component_el_table_column, {
          label: "注册时间",
          prop: "strCreateTime"
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            icon: _unref(Edit),
            onClick: $event => openUpdate(scope.row.id),
            plain: "",
            size: "small",
            type: "primary"
          }, null, 8, ["icon", "onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])])], 64);
    };
  }
};