import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watchEffect } from 'vue';
import { shopSelect } from "@/api/shop";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'store-autocomplete',
  props: {
    styleWidth: String,
    value: String
  },
  emits: ["autocompleteSelectData", "autocompleteChangeData"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const widthStyle = ref(props.styleWidth);
    let shopValue = ref('');
    watchEffect(() => {
      shopValue.value = props.value === undefined ? "" : props.value;
    });
    let list = ref([]);
    const querySearchAsync = async e => {
      try {
        const result = await shopSelect(e);
        list.value = [];
        for (let i = 0; i < result.data.storeNameFindDetailInfoList.length; i++) {
          const element = result.data.storeNameFindDetailInfoList[i];
          const item = {
            value: element.vname,
            id: element.id
          };
          list.value.push(item);
        }
      } catch (error) {
        ElMessage.error("刷新重试！");
      }
      return list.value;
    };
    const emit = __emit;
    const handleSelect = e => {
      emit('autocompleteSelectData', e.id);
    };
    const handChange = e => {
      emit('autocompleteChangeData', e);
    };
    return (_ctx, _cache) => {
      const _component_el_autocomplete = _resolveComponent("el-autocomplete");
      return _openBlock(), _createBlock(_component_el_autocomplete, {
        modelValue: _unref(shopValue),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(shopValue) ? shopValue.value = $event : shopValue = $event),
        "fetch-suggestions": querySearchAsync,
        "trigger-on-focus": false,
        onSelect: handleSelect,
        onChange: handChange,
        placeholder: "门店名称",
        clearable: "",
        style: _normalizeStyle({
          width: widthStyle.value
        })
      }, null, 8, ["modelValue", "style"]);
    };
  }
};