import {createRouter, createWebHashHistory} from 'vue-router'
import store from "@/store";
import {generateRoutes} from "@/router/asyncRouter";

const routes = [
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: {
            title: "登录"
        },
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('@/views/404'),
        meta: {
            title: "404"
        },
    },
    {
        path: '/',
        name: "layout",
        component: () => import('@/views/layout'),
        children: []
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    linkActiveClass: "active",
    routes
})

// 检查是否存在于免登陆白名单
function inWhiteList(toPath) {
    const whiteList = ['/login', '/404']
    const path = whiteList.find((value) => {
        // 使用正则匹配
        const reg = new RegExp('^' + value)
        return reg.test(toPath)
    })
    return !!path
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }

    const token = store.getters.getToken
    let isLoadRouters = store.getters.getIsLoadRouters

    if (inWhiteList(to.path)) {
        next()
    } else {
        //用户已登录
        if (token && JSON.stringify(store.getters.getMenuList) !== '[]') {
            if (isLoadRouters) {
                // 路由已添加，直接跳转到目标页面
                next()
            } else {
                //解决刷新页面空白
                //重新加载路由，并跳转到目标页
                let menuList = store.getters.getMenuList
                store.commit('setIsLoadRouters', true)
                //添加动态路由
                generateRoutes(menuList)

                next({...to, replace: false})
            }
        } else {
            // 无登录信息，跳转到登录页
            store.commit('setIsLoadRouters', false)
            next(`/login`)
        }
    }

})


export default router



