import request from "@/utils/request";

/**
 * 商品列表
 * */
export function goodsList(data) {
    return request({
        url: '/admin/good/search',
        method: 'post',
        data
    })
}

/**
 * 添加商品
 * */
export function addGoods(data) {
    return request({
        url: '/admin/good/doAdd',
        method: 'post',
        data
    })
}

/**
 * 添加商品
 * */
export function goodsDetail(id) {
    return request({
        url: '/admin/good/detail',
        method: 'post',
        data: {id}
    })
}

export function updateGoods(data) {
    return request({
        url: '/admin/good/doUpdate',
        method: 'post',
        data
    })
}