import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, ref, watchEffect } from 'vue';
import { cityList } from "@/api/public";
export default {
  __name: 'city-autocomplete',
  props: {
    styleWidth: String,
    value: String
  },
  emits: ["autocompleteSelectData", "autocompleteChangeData"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const widthStyle = ref(props.styleWidth);
    const cityValue = ref(props.value);
    watchEffect(() => {
      cityValue.value = props.value === undefined ? "" : props.value;
    });
    const restaurants = ref([]);
    onMounted(() => {
      cityList().then(res => {
        for (let item in res.data.cityAdcodeValues) {
          for (let i = 0; i < res.data.cityAdcodeValues[item].length; i++) {
            const element = res.data.cityAdcodeValues[item][i];
            const data = {
              value: element.cityName,
              adcode: element.adcode
            };
            restaurants.value.push(data);
          }
        }
      });
    });
    const querySearch = (queryString, cb) => {
      const results = queryString ? restaurants.value.filter(createFilter(queryString)) : restaurants.value;
      // call callback function to return suggestions
      cb(results);
    };
    const createFilter = queryString => {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    };
    const emit = __emit;
    const handleSelect = e => {
      emit('autocompleteSelectData', e);
    };
    const handChange = e => {
      emit('autocompleteChangeData', e);
    };
    return (_ctx, _cache) => {
      const _component_el_autocomplete = _resolveComponent("el-autocomplete");
      return _openBlock(), _createBlock(_component_el_autocomplete, {
        modelValue: cityValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => cityValue.value = $event),
        "fetch-suggestions": querySearch,
        "trigger-on-focus": false,
        onSelect: handleSelect,
        onChange: handChange,
        placeholder: "搜索城市",
        clearable: "",
        style: _normalizeStyle({
          width: widthStyle.value
        })
      }, null, 8, ["modelValue", "style"]);
    };
  }
};