import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46329f14"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "table-content"
};
const _hoisted_3 = {
  class: "myPagination"
};
import { Search } from "@element-plus/icons-vue";
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, reactive, ref } from "vue";
import ZxLeaseTermOrderDetails from "@/views/zx/components/zxLeaseTermOrderDetails.vue";
import { leaseTermList, leaseTermOrderList } from "@/api/zx";
import { carRentalOrderList } from "@/api/order";
export default {
  __name: 'zxLeaseTermOrder',
  setup(__props) {
    const searchData = reactive({
      "phone": "",
      "idCard": "",
      "knightName": "",
      "orderNo": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      leaseTermOrderList(searchData.valueOf()).then(res => {
        console.log(res);
        if (res.result === 0) {
          tableData.value = res.data.zxRentBuyOrderDeductionDetailSearchResponseDataInfo;
          total.value = res.data.totalCount;
        }
      });
    };
    onMounted(() => {
      search();
    });
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };

    //打开详情
    const drawerDetails = ref(false);
    const isOpenDrawer = () => {
      drawerDetails.value = !drawerDetails.value;
    };
    let id = ref("");
    const openDetails = data => {
      id.value = data;
      isOpenDrawer();
    };
    const expandedRows = ref([]);

    /**树形点击事件 */
    function expandChange(row, expandedRows) {
      const isExpanded = expandedRows.includes(row);
      //判断开启下拉和关闭下拉
      if (!isExpanded) {
        return;
      }
      const searchData = reactive({
        "zxRentBuyOrderDeducationId": row.id,
        "pageNo": 1,
        "pageSize": 100
      });
      leaseTermList(searchData.valueOf()).then(res => {
        row.detailList = res.data.zxRentBuyOrderDeductionVDetailSearchResponseDataInfoResponseDataInfo;
      });
    }
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "志忻租期订单管理"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.phone,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.phone = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.idCard,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.idCard = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.knightName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.knightName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.orderNo,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.orderNo = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "志忻订单号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value,
        "row-key": "id",
        onExpandChange: expandChange,
        "expand-row-keys": expandedRows.value,
        border: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "expand"
        }, {
          default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
            data: scope.row.detailList
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table_column, {
              label: "会员ID",
              prop: "id"
            }), _createVNode(_component_el_table_column, {
              label: "手机号码",
              prop: "phone"
            }), _createVNode(_component_el_table_column, {
              label: "证件号码",
              prop: "idCard"
            }), _createVNode(_component_el_table_column, {
              width: "80",
              label: "用户姓名",
              prop: "knightName"
            }), _createVNode(_component_el_table_column, {
              label: "志忻订单号",
              prop: "orderNo"
            }), _createVNode(_component_el_table_column, {
              label: "代扣订单号",
              prop: "breachOrderNo"
            }), _createVNode(_component_el_table_column, {
              label: "扣款金额",
              prop: "amount"
            }), _createVNode(_component_el_table_column, {
              label: "开始时间",
              prop: "startTime"
            }), _createVNode(_component_el_table_column, {
              label: "结束时间",
              prop: "endTime"
            }), _createVNode(_component_el_table_column, {
              label: "当期数",
              prop: "period"
            }), _createVNode(_component_el_table_column, {
              label: "更新时间",
              prop: "updateTime"
            }), _createVNode(_component_el_table_column, {
              label: "订单状态",
              prop: "strStatus"
            }), _createVNode(_component_el_table_column, {
              label: "预计扣款时间",
              prop: "deductTime"
            }), _createVNode(_component_el_table_column, {
              label: "门店名称",
              prop: "storeName"
            })]),
            _: 2
          }, 1032, ["data"])])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "手机号码",
          prop: "phone"
        }), _createVNode(_component_el_table_column, {
          label: "证件号码",
          prop: "idCard"
        }), _createVNode(_component_el_table_column, {
          label: "姓名",
          prop: "knightName"
        }), _createVNode(_component_el_table_column, {
          label: "志忻订单号",
          prop: "orderNo"
        }), _createVNode(_component_el_table_column, {
          label: "总期数",
          prop: "periods",
          width: "80"
        }), _createVNode(_component_el_table_column, {
          label: "总代扣金额",
          prop: "totalAmt"
        }), _createVNode(_component_el_table_column, {
          label: "同步时间",
          prop: "syncDate"
        }), _createVNode(_component_el_table_column, {
          label: "车牌号",
          prop: "carNumber",
          width: "80"
        }), _createVNode(_component_el_table_column, {
          label: "车架号",
          prop: "assetCode"
        }), _createVNode(_component_el_table_column, {
          label: "车辆编号",
          prop: "bikeNo"
        }), _createVNode(_component_el_table_column, {
          label: "门店名称",
          prop: "storeName"
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            onClick: $event => openDetails(scope.row.id),
            size: "small",
            type: "primary",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("详情")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "expand-row-keys"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_drawer, {
        modelValue: drawerDetails.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => drawerDetails.value = $event),
        title: "志忻订单详情",
        size: "60%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [drawerDetails.value ? (_openBlock(), _createBlock(ZxLeaseTermOrderDetails, {
          key: 0,
          id: _unref(id)
        }, null, 8, ["id"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};