import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import { reactive, onMounted, ref } from 'vue';
import PageHeaderComponents from "@/components/page-header.vue";
import { userAdd, userDetail, userUpdate } from "@/api/user";
import { useRouter } from "vue-router";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'update',
  setup(__props) {
    const router = useRouter();
    const formData = reactive({
      mobile: '',
      trueName: '',
      istatus: 0
    });

    //用户ID
    let id = ref(0);
    onMounted(() => {
      id.value = history.state.id;
      if (id.value !== 0) {
        //获取用户详情
        userDetail(id.value).then(res => {
          formData.mobile = res.data.mobile;
          formData.istatus = res.data.istatus;
          formData.trueName = res.data.trueName;
        });
      }
    });
    const add = () => {
      userAdd(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const update = () => {
      const updateData = {
        id: id.value,
        trueName: formData.valueOf().trueName,
        istatus: formData.valueOf().istatus
      };
      userUpdate(updateData).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const submitForm = () => {
      if (id.value !== 0) {
        update();
      } else {
        add();
      }
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PageHeaderComponents, {
        label: _unref(id) !== 0 ? '修改用户' : '添加用户'
      }, null, 8, ["label"]), _createVNode(_component_el_form, {
        model: formData,
        "label-position": "top",
        "label-width": "auto",
        "status-icon": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "姓名",
          prop: "trueName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.trueName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.trueName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "电话号码",
          prop: "mobile",
          type: "number"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.mobile,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.mobile = $event),
            disabled: _unref(id) !== 0
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "用户状态",
          prop: "istatus",
          type: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.istatus,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.istatus = $event),
            placeholder: "状态"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "启用",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "禁用",
              value: 1
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[3] || (_cache[3] = $event => submitForm())
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};