import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "myPagination"
};
const _hoisted_6 = {
  class: "drawer-img"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { Edit, Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { onBeforeMount, reactive, ref } from "vue";
import { typeList } from "@/api/vehicle";
export default {
  __name: 'vehicleType',
  setup(__props) {
    const searchData = reactive({
      "vname": "",
      "istatus": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const router = useRouter();
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      typeList(searchData.valueOf()).then(res => {
        tableData.value = res.data.carTypeDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    const openUpdate = id => {
      router.push({
        name: 'vehicleTypeUpdate',
        state: {
          id
        }
      });
    };
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };
    const drawerImg = ref(false);
    const itemData = ref(null);
    const viewImg = data => {
      drawerImg.value = !drawerImg.value;
      itemData.value = data;
    };
    onBeforeMount(() => {
      search();
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "车型表"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.vname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.vname = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "名称"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.istatus,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.istatus = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "启用",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "禁用",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      }), _createVNode(_component_el_button, {
        plain: "",
        type: "warning",
        onClick: _cache[2] || (_cache[2] = $event => openUpdate(0))
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "名称",
          prop: "vname"
        }), _createVNode(_component_el_table_column, {
          label: "描述",
          prop: "vdesc"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_popover, {
            placement: "right",
            trigger: "click",
            width: "30%"
          }, {
            reference: _withCtx(() => [_createVNode(_component_el_button, {
              style: {
                "margin-right": "16px"
              }
            }, {
              default: _withCtx(() => [_createTextVNode("查看车型描述")]),
              _: 1
            })]),
            default: _withCtx(() => [_createElementVNode("div", {
              innerHTML: scope.row.vdesc
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "图片",
          prop: "imageUrl1,imageUrl2,imageUrl3"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            onClick: $event => viewImg(scope.row),
            type: "primary",
            link: ""
          }, {
            default: _withCtx(() => [_createTextVNode("查看图片")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          prop: "istatus"
        }, {
          default: _withCtx(scope => [scope.row.istatus === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_3, "启用")) : _createCommentVNode("", true), scope.row.istatus === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_4, "禁用")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            icon: _unref(Edit),
            onClick: $event => openUpdate(scope.row.id),
            plain: "",
            size: "small",
            type: "primary"
          }, null, 8, ["icon", "onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_drawer, {
        modelValue: drawerImg.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => drawerImg.value = $event),
        title: "车辆图片",
        direction: "ttb",
        size: "50%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_image, {
          "preview-src-list": [itemData.value.imageUrl1],
          src: itemData.value.imageUrl1
        }, null, 8, ["preview-src-list", "src"]), _createVNode(_component_el_image, {
          "preview-src-list": [itemData.value.imageUrl2],
          src: itemData.value.imageUrl2
        }, null, 8, ["preview-src-list", "src"]), _createVNode(_component_el_image, {
          "preview-src-list": [itemData.value.imageUrl3],
          src: itemData.value.imageUrl3
        }, null, 8, ["preview-src-list", "src"])])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};