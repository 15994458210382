export default {
  name: "pageHeaderComponents",
  props: {
    label: {
      value: "label"
    }
  },
  methods: {
    goBack() {
      history.back();
    }
  }
};