import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
const _hoisted_2 = ["src"];
import PageHeaderComponents from "@/components/page-header.vue";
import { reactive, ref, onMounted } from "vue";
import { Plus } from "@element-plus/icons-vue";
import { UPLOAD_FILE_HEADER, UPLOAD_FILE_URL } from "@/utils/config";
import { addShop, detail, updateShop } from "@/api/shop";
import { useRouter } from "vue-router";
import CityAutocomplete from "@/components/city-autocomplete.vue";
import CompanyAutocomplete from "@/components/company-autocomplete.vue";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'update',
  setup(__props) {
    const router = useRouter();
    const formData = reactive({
      "vname": "",
      "linkTel": "",
      "address": "",
      "itype": "",
      "istatus": "",
      "franchiseType": "",
      "imagesList": [],
      "adcode": "",
      "companyCode": ""
    });
    let fileImgList = ref([]);
    const imgLabel = ref("图片上传");
    const shopType = () => {
      const itype = formData.valueOf().itype;
      if (itype === 0) {
        imgLabel.value = "门店图片上传";
      } else if (itype === 1) {
        imgLabel.value = "点位图片上传";
      } else if (itype === 2) {
        imgLabel.value = "仓库图片上传";
      }
    };
    const dialogImageUrl = ref('');
    const dialogVisible = ref(false);

    //点击放大图片
    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    const cityValue = ref("");
    const companyVname = ref("");

    //用户ID
    let id = ref(0);
    onMounted(() => {
      id.value = history.state.id;
      if (id.value !== 0) {
        //获取用户详情
        detail(id.value).then(res => {
          console.log(res);
          formData.valueOf().vname = res.data.vname;
          formData.valueOf().linkTel = res.data.linkTel;
          formData.valueOf().address = res.data.address;
          formData.valueOf().itype = res.data.itype;
          formData.valueOf().istatus = res.data.istatus;
          formData.valueOf().franchiseType = res.data.franchiseType;
          formData.valueOf().adcode = res.data.adcode;
          formData.valueOf().companyCode = res.data.companyCode;
          cityValue.value = res.data.cityName;
          companyVname.value = res.data.companyVname;
          fileImgList.value.push({
            url: res.data.imageUrl1
          });
          fileImgList.value.push({
            url: res.data.imageUrl2
          });
          fileImgList.value.push({
            url: res.data.imageUrl3
          });
        });
      }
    });
    const formattingImg = () => {
      formData.valueOf().imagesList = [];
      fileImgList.value.forEach(element => {
        if (element.response !== undefined) {
          formData.valueOf().imagesList.push(element.response.data);
        } else {
          formData.valueOf().imagesList.push(element.url);
        }
      });
    };
    const add = () => {
      addShop(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const update = () => {
      formData.valueOf().id = id.value;
      delete formData.valueOf().itype;
      updateShop(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const submit = () => {
      formattingImg();
      console.log(formData.valueOf());
      if (id.value === 0) {
        add();
      } else {
        update();
      }
    };
    const getCityAdcode = res => {
      formData.adcode = res.adcode;
    };
    const companySelectCallBack = res => {
      formData.companyCode = res;
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PageHeaderComponents, {
        label: _unref(id) !== 0 ? '修改门店' : '添加门店'
      }, null, 8, ["label"]), _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "名称",
          prop: "vname"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.vname,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.vname = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "地址",
          prop: "address"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.address,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.address = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "联系电话",
          prop: "linkTel"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.linkTel,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.linkTel = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "类型",
          prop: "itype"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            disabled: _unref(id) !== 0,
            modelValue: formData.itype,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.itype = $event),
            placeholder: "类型",
            onChange: shopType
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "门店",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "点位",
              value: 1
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 2,
              label: "仓库",
              value: 2
            }))]),
            _: 1
          }, 8, ["disabled", "modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "istatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.istatus,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.istatus = $event),
            placeholder: "状态"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "启用",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "禁用",
              value: 1
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "运营方式",
          prop: "franchiseType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.franchiseType,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.franchiseType = $event),
            placeholder: "运营方式"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              key: "",
              label: "运营方式",
              value: ""
            }), (_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "直营",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "加盟",
              value: 1
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "门店城市",
          prop: "franchiseType"
        }, {
          default: _withCtx(() => [_createVNode(CityAutocomplete, {
            value: cityValue.value,
            styleWidth: "100%",
            onAutocompleteSelectData: getCityAdcode
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "所属商户",
          prop: "franchiseType"
        }, {
          default: _withCtx(() => [_createVNode(CompanyAutocomplete, {
            disabled: _unref(id) !== 0,
            value: companyVname.value,
            onCompanySelectOnChange: companySelectCallBack,
            styleWidth: "100%"
          }, null, 8, ["disabled", "value"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: imgLabel.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_upload, {
            "file-list": _unref(fileImgList),
            "onUpdate:fileList": _cache[6] || (_cache[6] = $event => _isRef(fileImgList) ? fileImgList.value = $event : fileImgList = $event),
            action: _unref(UPLOAD_FILE_URL),
            headers: _unref(UPLOAD_FILE_HEADER),
            "on-preview": handlePictureCardPreview,
            "list-type": "picture-card",
            limit: 3
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_unref(Plus))]),
              _: 1
            })]),
            _: 1
          }, 8, ["file-list", "action", "headers"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: submit,
            plain: "",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => dialogVisible.value = $event)
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: dialogImageUrl.value,
          alt: "Preview Image"
        }, null, 8, _hoisted_2)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};