import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "myPagination"
};
import { Search } from "@element-plus/icons-vue";
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, reactive, ref } from "vue";
import { leaseTermList } from "@/api/zx";
import ZxLeaseTermDetails from "@/views/zx/components/zxLeaseTermDetails.vue";
export default {
  __name: 'zxLeaseTerm',
  setup(__props) {
    const searchData = reactive({
      "phone": "",
      "idCard": "",
      "knightName": "",
      "orderNo": "",
      "breachOrderNo": "",
      "status": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      leaseTermList(searchData.valueOf()).then(res => {
        tableData.value = res.data.zxRentBuyOrderDeductionVDetailSearchResponseDataInfoResponseDataInfo;
        total.value = res.data.totalCount;
      });
    };
    onMounted(() => {
      search();
    });
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };

    //打开详情
    const drawerDetails = ref(false);
    const isOpenDrawer = () => {
      drawerDetails.value = !drawerDetails.value;
    };
    let id = ref("");
    const openDetails = data => {
      id.value = data;
      isOpenDrawer();
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "志忻租期管理"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.phone,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.phone = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.idCard,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.idCard = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.knightName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.knightName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.orderNo,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.orderNo = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "志忻订单号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.breachOrderNo,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => searchData.breachOrderNo = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "代扣订单号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.status,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => searchData.status = $event),
        placeholder: "订单状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "订单状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "待扣款",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "扣款中",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "扣款成功",
          value: 2
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 3,
          label: "扣款失败",
          value: 3
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "会员ID",
          prop: "id"
        }), _createVNode(_component_el_table_column, {
          label: "手机号码",
          prop: "phone"
        }), _createVNode(_component_el_table_column, {
          label: "证件号码",
          prop: "idCard"
        }), _createVNode(_component_el_table_column, {
          label: "用户姓名",
          prop: "knightName",
          width: "80"
        }), _createVNode(_component_el_table_column, {
          label: "志忻订单号",
          prop: "orderNo"
        }), _createVNode(_component_el_table_column, {
          label: "代扣订单号",
          prop: "breachOrderNo"
        }), _createVNode(_component_el_table_column, {
          label: "扣款金额",
          prop: "amount"
        }), _createVNode(_component_el_table_column, {
          label: "开始时间",
          prop: "startTime"
        }), _createVNode(_component_el_table_column, {
          label: "结束时间",
          prop: "endTime"
        }), _createVNode(_component_el_table_column, {
          label: "当期数",
          prop: "period"
        }), _createVNode(_component_el_table_column, {
          label: "更新时间",
          prop: "updateTime"
        }), _createVNode(_component_el_table_column, {
          label: "订单状态",
          prop: "strStatus"
        }), _createVNode(_component_el_table_column, {
          label: "预计扣款时间",
          prop: "deductTime"
        }), _createVNode(_component_el_table_column, {
          label: "门店名称",
          prop: "storeName"
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            onClick: $event => openDetails(scope.row.id),
            size: "small",
            type: "primary",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("详情")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_drawer, {
        modelValue: drawerDetails.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => drawerDetails.value = $event),
        title: "志忻订单详情",
        size: "60%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [drawerDetails.value ? (_openBlock(), _createBlock(ZxLeaseTermDetails, {
          key: 0,
          id: _unref(id)
        }, null, 8, ["id"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};