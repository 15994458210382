import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "myPagination"
};
import { Search } from "@element-plus/icons-vue";
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, reactive, ref } from "vue";
import { changeList, zxCancelConfirm, zxCarExamine } from "@/api/zx";
import ZxChangeDetails from "@/views/zx/components/zxChangeDetails.vue";

import { ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'zxChange',
  setup(__props) {
    const searchData = reactive({
      "phone": "",
      "idCard": "",
      "knightName": "",
      "istatus": "",
      "operateType": "1",
      "orderNo": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      changeList(searchData.valueOf()).then(res => {
        tableData.value = res.data.zxRentPickCarDetailResponseDataInfoList;
        total.value = res.data.totalCount;
      });
    };
    onMounted(() => {
      search();
    });
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };

    //打开详情
    const drawerDetails = ref(false);
    const isOpenDrawer = () => {
      drawerDetails.value = !drawerDetails.value;
    };
    let id = ref("");
    const openDetails = data => {
      id.value = data;
      isOpenDrawer();
    };
    const examine = (id, istatus) => {
      let textContetn = "确定要执行操作吗？？？";
      if (istatus === 0) {
        textContetn = "确定要执行撤销操作吗？？？";
      } else if (istatus === 1) {
        textContetn = "确定要执行核销操作吗？？？";
      }
      ElMessageBox.confirm(textContetn, '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        zxCarExamine(id, istatus).then(res => {
          if (res.result === 0) {
            ElMessage({
              message: '成功',
              type: 'success'
            });
            search();
          } else {
            ElMessage({
              message: res.msg,
              type: 'error'
            });
          }
        });
      });
    };
    const revocation = id => {
      ElMessageBox.confirm("确定要撤销已核销吗？", '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        zxCancelConfirm(id).then(res => {
          if (res.result === 0) {
            ElMessage({
              message: '成功',
              type: 'success'
            });
            search();
          } else {
            ElMessage({
              message: res.msg,
              type: 'error'
            });
          }
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "志忻取换退车管理"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.phone,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.phone = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.idCard,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.idCard = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.knightName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.knightName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.istatus,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.istatus = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "待审核",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "已审核",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "已撤销",
          value: 2
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 3,
          label: "已完成",
          value: 3
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_input, {
        modelValue: searchData.orderNo,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => searchData.orderNo = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "志忻订单号"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "会员手机号码",
          prop: "phone"
        }), _createVNode(_component_el_table_column, {
          label: "身份证",
          prop: "idCard"
        }), _createVNode(_component_el_table_column, {
          label: "用户姓名",
          prop: "knightName"
        }), _createVNode(_component_el_table_column, {
          label: "志忻订单号",
          prop: "orderNo"
        }), _createVNode(_component_el_table_column, {
          label: "操作类型",
          prop: "strOperateType"
        }), _createVNode(_component_el_table_column, {
          label: "核销码",
          prop: "qrCode"
        }), _createVNode(_component_el_table_column, {
          label: "状态描述",
          prop: "strIstatus"
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            onClick: $event => openDetails(scope.row.id),
            size: "small",
            type: "primary",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("详情")]),
            _: 2
          }, 1032, ["onClick"]), scope.row.cancelButton === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: $event => examine(scope.row.id, 0),
            size: "small",
            type: "warning",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("撤销 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.confirmButton === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            onClick: $event => examine(scope.row.id, 1),
            size: "small",
            type: "success",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("核销 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), scope.row.cancelConfirmButton === 1 ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            onClick: $event => revocation(scope.row.id),
            size: "small",
            type: "danger",
            plain: ""
          }, {
            default: _withCtx(() => [_createTextVNode("撤销核销 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])]), _createVNode(_component_el_drawer, {
        modelValue: drawerDetails.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => drawerDetails.value = $event),
        title: "志忻换取换退车详情",
        size: "50%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [drawerDetails.value ? (_openBlock(), _createBlock(ZxChangeDetails, {
          key: 0,
          id: _unref(id)
        }, null, 8, ["id"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};