import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, reactive, ref } from "vue";
import { cartype } from "@/api/vehicle";
import Editor from "@/components/editor.vue";
import { addGoods, goodsDetail, updateGoods } from "@/api/goods";
import { useRouter } from "vue-router";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'update',
  setup(__props) {
    const router = useRouter();
    const formData = reactive({
      "vname": "",
      "carTypeId": "",
      "useType": "",
      "rentPrice": "",
      "depositPrice": "",
      "rentDays": "",
      "rentTerm": "",
      "vdesc": ""
    });

    //获取富文本编辑器的内容
    const getEditorValue = value => {
      formData.valueOf().vdesc = value;
    };
    const add = () => {
      addGoods(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    let id = ref(0);
    const carTypeList = ref([]);
    onMounted(() => {
      id.value = history.state.id;
      cartype().then(res => {
        carTypeList.value = res.data;
        if (id.value !== 0) {
          //获取用户详情
          goodsDetail(id.value).then(res => {
            formData.valueOf().istatus = res.data.istatus;
            formData.valueOf().vname = res.data.vname;
            formData.valueOf().carTypeId = res.data.carTypeId;
            formData.valueOf().useType = res.data.useType;
            formData.valueOf().rentPrice = res.data.rentPrice;
            formData.valueOf().depositPrice = res.data.depositPrice;
            formData.valueOf().rentDays = res.data.rentDays;
            formData.valueOf().rentTerm = res.data.rentTerm;
            formData.valueOf().vdesc = res.data.vdesc;
          });
        }
      });
    });
    const update = () => {
      formData.valueOf().id = id.value;
      updateGoods(formData.valueOf()).then(res => {
        if (res.result === 0) {
          ElMessage({
            message: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            router.go(-1);
          }, 1500);
        } else {
          ElMessage.error(res.msg);
        }
      });
    };
    const submit = () => {
      if (id.value === 0) {
        add();
      } else {
        update();
      }
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_card = _resolveComponent("el-card");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(PageHeaderComponents, {
        label: _unref(id) !== 0 ? '修改商品' : '添加商品'
      }, null, 8, ["label"]), _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "商品名称",
          prop: "vname"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.vname,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.vname = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "车辆类型",
          prop: "carTypeId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.carTypeId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.carTypeId = $event),
            placeholder: "类型"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carTypeList.value, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.vname,
                value: item.id
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "使用类型",
          prop: "useType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.useType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.useType = $event),
            placeholder: "类型"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              value: 0,
              label: "先用后付"
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              value: 1,
              label: "先付后用"
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "每期租金",
          prop: "rentPrice"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.rentPrice,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.rentPrice = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "押金",
          prop: "depositPrice"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.depositPrice,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.depositPrice = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "每期天数",
          prop: "rentDays"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.rentDays,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.rentDays = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "租期",
          prop: "rentTerm"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formData.rentTerm,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.rentTerm = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_card, {
            shadow: "never"
          }, {
            header: _withCtx(() => [_createTextVNode(" 商品描述 ")]),
            default: _withCtx(() => [_createVNode(Editor, {
              html: formData.vdesc,
              onEditorValueChang: getEditorValue
            }, null, 8, ["html"])]),
            _: 1
          })]),
          _: 1
        }), _unref(id) !== 0 ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "状态",
          prop: "istatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formData.istatus,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formData.istatus = $event),
            placeholder: "状态"
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_component_el_option, {
              key: 0,
              label: "已创建",
              value: 0
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 1,
              label: "上架",
              value: 1
            })), (_openBlock(), _createBlock(_component_el_option, {
              key: 2,
              label: "下架",
              value: 2
            }))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            plain: "",
            type: "primary",
            onClick: submit
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};