import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
import { ref, watchEffect } from "vue";
import { changeDetails } from "@/api/zx";
export default {
  __name: 'zxChangeDetails',
  props: {
    id: String
  },
  setup(__props) {
    const props = __props;
    const formData = ref({});
    watchEffect(() => {
      if (props.id !== "") {
        changeDetails(props.id).then(res => {
          formData.value = res.data;
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData.value,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "ID主键",
          prop: "id"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.id = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "会员手机号码",
          prop: "phone"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.phone,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.phone = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证",
          prop: "idCard"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.idCard,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.idCard = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "用户姓名",
          prop: "knightName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.knightName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.knightName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "志欣订单号",
          prop: "orderNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.orderNo,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.orderNo = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作类型",
          prop: "strOperateType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strOperateType,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.value.strOperateType = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "站点id",
          prop: "stationNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.stationNo,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.value.stationNo = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "核销二维码",
          prop: "qrCode"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            src: formData.value.qrCode
          }, null, 8, ["src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "换车原因",
          prop: "reason"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.reason,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formData.value.reason = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "有效期开始时间",
          prop: "startTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.startTime,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formData.value.startTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "有效期结束时间",
          prop: "endTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.endTime,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formData.value.endTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "strIstatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strIstatus,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formData.value.strIstatus = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "同步时间",
          prop: "syncTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.syncTime,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formData.value.syncTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作人",
          prop: "optUser"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.optUser,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => formData.value.optUser = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "更新时间",
          prop: "updateTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.updateTime,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => formData.value.updateTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};