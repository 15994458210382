import request from "@/utils/request";

/**
 * 城市列表
 * */
export function cityList() {
    return request({
        url: '/admin/city/list',
        method: 'post'
    })
}

/**
 * 商户列表
 * */
export function companyList() {
    return request({
        url: '/admin/store/company/list',
        method: 'post'
    })
}