import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue";
import { onMounted, ref, watchEffect } from "vue";
import { companyList } from "@/api/public";
export default {
  __name: 'company-autocomplete',
  props: {
    styleWidth: String,
    value: String
  },
  emits: ["companySelectOnChange"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const widthStyle = ref(props.styleWidth);
    const companyValue = ref(props.value);
    watchEffect(() => {
      companyValue.value = props.value === undefined ? "" : props.value;
    });
    const list = ref([]);
    onMounted(() => {
      companyList().then(res => {
        list.value = res.data.storeCompanyDetailInfoList;
      });
    });
    const emit = __emit;
    const companySelectOnChange = e => {
      emit('companySelectOnChange', e);
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createBlock(_component_el_select, {
        modelValue: companyValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => companyValue.value = $event),
        onChange: companySelectOnChange,
        style: _normalizeStyle({
          width: widthStyle.value
        }),
        placeholder: "所属商户"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.companyCode,
            label: item.vname,
            value: item.companyCode
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "style"]);
    };
  }
};