import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3df99a54"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout"
};
const _hoisted_2 = {
  class: "layoutContainer"
};
import Menu from "@/views/layout/element/menu.vue";
import Container from "@/views/layout/element/container.vue";
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Menu), _createVNode(Container)])]);
    };
  }
};