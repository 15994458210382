import router from '@/router';
import axios from 'axios';
import store from '@/store';
import { BASE_URL } from "@/utils/config";
const service = axios.create({
  baseURL: BASE_URL,
  // 代理
  timeout: 20000,
  // request timeout
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});
let loading = null;

// 添加请求拦截器，若token存在则在请求头中加token，不存在也继续请求
service.interceptors.request.use(config => {
  const loadingAnimation = config.loading === undefined ? true : config.loading;
  if (loadingAnimation) {
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  }
  if (store.getters.getToken) {
    config.headers.Logintoken = store.getters.getToken;
  }
  return config;
}, error => {
  loading.close();
  return Promise.reject(error);
});

// response拦截器
service.interceptors.response.use(response => {
  loading.close();
  //登录失效
  if (response.data.result === 2) {
    ElMessage.error("登录失效，请重新登录！");
    router.replace({
      path: '/login'
    });
  }
  // 在status正确的情况下，code不正确则返回对应的错误信息（后台自定义为200是正确，并且将错误信息写在message），正确则返回响应
  return response.status === 200 ? response.data : Promise.reject(response.data.message);
}, error => {
  loading.close();
  ElMessage.error(error.message);
  // 在status不正确的情况下，判别status状态码给出对应响应
  return Promise.reject(error);
});
export default service;