import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "myPagination"
};
import PageHeaderComponents from "@/components/page-header.vue";
import { Edit, Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { reactive, onMounted, ref } from "vue";
import { cartype } from "@/api/vehicle";
import { goodsList } from "@/api/goods";
export default {
  __name: 'list',
  setup(__props) {
    const router = useRouter();
    const searchData = reactive({
      "vname": "",
      "istatus": "",
      "carTypeId": "",
      "useType": "",
      "pageNo": 1,
      "pageSize": 10
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      goodsList(searchData.valueOf()).then(res => {
        tableData.value = res.data.goodDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    const carTypeList = ref([]);
    onMounted(() => {
      cartype().then(res => {
        carTypeList.value = res.data;
      });
      search();
    });
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };
    const openUpdate = id => {
      router.push({
        name: "updateGoods",
        state: {
          id
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "商品列表"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.vname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.vname = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "商品名称"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.istatus,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.istatus = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "已创建",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "上架",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "下架",
          value: 2
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_select, {
        modelValue: searchData.carTypeId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.carTypeId = $event),
        placeholder: "车辆类型"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "车辆类型",
          value: ""
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carTypeList.value, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.vname,
            value: item.id
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_select, {
        modelValue: searchData.useType,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.useType = $event),
        placeholder: "使用类型"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "使用类型",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "先用后付",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "先付后用",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      }), _createVNode(_component_el_button, {
        plain: "",
        type: "warning",
        onClick: _cache[4] || (_cache[4] = $event => openUpdate(0))
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "名称",
          prop: "vname"
        }), _createVNode(_component_el_table_column, {
          label: "车型",
          prop: "strCarTypeName"
        }), _createVNode(_component_el_table_column, {
          label: "类型",
          prop: "strUseType"
        }), _createVNode(_component_el_table_column, {
          label: "每期租金",
          prop: "rentPrice"
        }), _createVNode(_component_el_table_column, {
          label: "每期天数",
          prop: "rentDays"
        }), _createVNode(_component_el_table_column, {
          label: "租期",
          prop: "rentTerm"
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            icon: _unref(Edit),
            onClick: $event => openUpdate(scope.row.id),
            plain: "",
            size: "small",
            type: "primary"
          }, null, 8, ["icon", "onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
        "current-page": searchData.pageNo,
        "page-size": searchData.pageSize,
        background: "",
        layout: "prev, pager, next, jumper",
        total: total.value,
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])])], 64);
    };
  }
};