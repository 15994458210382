import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  class: "myPagination"
};
const _hoisted_3 = {
  class: "myPagination"
};
import { Search } from "@element-plus/icons-vue";
import PageHeaderComponents from "@/components/page-header.vue";
import { onMounted, reactive, ref } from "vue";
import { violationHostling, refund } from "@/api/violationHostling";
import HostlingDetails from "@/views/violationHostling/hostlingDetails.vue";

import { ElMessage, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
export default {
  __name: 'index',
  setup(__props) {
    const searchData = reactive({
      "mobile": "",
      "cardId": "",
      "trueName": "",
      "payStatus": "",
      "refundStatus": "",
      "itype": 0,
      "pageNo": 1,
      "pageSize": 5
    });
    const tableData = ref([]);
    const total = ref(0);
    const search = () => {
      violationHostling(searchData.valueOf()).then(res => {
        tableData.value = res.data.tenanceOrderDetailInfoList;
        total.value = res.data.totalCount;
      });
    };
    onMounted(() => {
      search();
    });
    const activeName = ref("0");
    const handleClick = tabIndex => {
      searchData.valueOf().itype = tabIndex;
      searchData.valueOf().pageNo = 1;
      tableData.value = [];
      total.value = 0;
      search();
    };
    const handleCurrentChange = val => {
      searchData.valueOf().pageNo = val;
      search();
    };
    const open = id => {
      ElMessageBox.prompt('请输入要退款的金额', '退款', {
        confirmButtonText: '确认退款',
        cancelButtonText: '取消'
      }).then(result => {
        ElMessageBox.confirm('请确认，是否要退款？', '退款提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refund(id, result.value).then(res => {
            if (res.result === 0) {
              ElMessage({
                type: 'success',
                message: res.msg
              });
              search();
            } else {
              ElMessage({
                type: 'error',
                message: res.msg
              });
            }
          });
        }).catch(() => {});
      }).catch(() => {});
    };

    //打开详情
    const drawerDetails = ref(false);
    const isOpenDrawer = () => {
      drawerDetails.value = !drawerDetails.value;
    };
    let id = ref("");
    const openDetails = data => {
      id.value = data;
      isOpenDrawer();
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "车辆整备/违章押金"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: searchData.mobile,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchData.mobile = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.cardId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => searchData.cardId = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "证件号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: searchData.trueName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => searchData.trueName = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: searchData.payStatus,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => searchData.payStatus = $event),
        placeholder: "支付状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "支付状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "已创建",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "已支付",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_select, {
        modelValue: searchData.refundStatus,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => searchData.refundStatus = $event),
        placeholder: "退款状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "退款状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "待退款",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "退款中",
          value: 1
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 2,
          label: "退款成功",
          value: 2
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => activeName.value = $event),
        class: "demo-tabs",
        onTabChange: handleClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "车辆整备",
          name: "0"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table, {
            data: tableData.value
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table_column, {
              label: "会员姓名",
              prop: "trueName"
            }), _createVNode(_component_el_table_column, {
              label: "手机号",
              prop: "mobile"
            }), _createVNode(_component_el_table_column, {
              label: "证件号码",
              prop: "cardId"
            }), _createVNode(_component_el_table_column, {
              label: "门店名称",
              prop: "storeName"
            }), _createVNode(_component_el_table_column, {
              label: "支付时间",
              prop: "strPaySuccessTime"
            }), _createVNode(_component_el_table_column, {
              label: "支付状态",
              prop: "strPayStatus"
            }), _createVNode(_component_el_table_column, {
              label: "支付金额",
              prop: "payPrice"
            }), _createVNode(_component_el_table_column, {
              prop: "id"
            }, {
              default: _withCtx(scope => [_createVNode(_component_el_button, {
                onClick: $event => openDetails(scope.row.id),
                size: "small",
                type: "primary",
                plain: ""
              }, {
                default: _withCtx(() => [_createTextVNode("详情")]),
                _: 2
              }, 1032, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_pagination, {
            "current-page": searchData.pageNo,
            "page-size": searchData.pageSize,
            background: "",
            layout: "prev, pager, next, jumper",
            total: total.value,
            "hide-on-single-page": true,
            onCurrentChange: handleCurrentChange
          }, null, 8, ["current-page", "page-size", "total"])])]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "违章押金",
          name: "1"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table, {
            data: tableData.value
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table_column, {
              label: "会员姓名",
              prop: "trueName"
            }), _createVNode(_component_el_table_column, {
              label: "手机号",
              prop: "mobile"
            }), _createVNode(_component_el_table_column, {
              label: "证件号码",
              prop: "cardId"
            }), _createVNode(_component_el_table_column, {
              label: "支付时间",
              prop: "strPaySuccessTime"
            }), _createVNode(_component_el_table_column, {
              label: "支付状态",
              prop: "strPayStatus"
            }), _createVNode(_component_el_table_column, {
              label: "支付金额",
              prop: "payPrice"
            }), _createVNode(_component_el_table_column, {
              label: "退款状态",
              prop: "strRefundStatus"
            }), _createVNode(_component_el_table_column, {
              label: "退款时间",
              prop: "strRefundTime"
            }), _createVNode(_component_el_table_column, {
              prop: "id,refundButtonStatus"
            }, {
              default: _withCtx(scope => [scope.row.refundButtonStatus === 1 ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                onClick: $event => open(scope.row.id),
                size: "small",
                type: "warning",
                plain: ""
              }, {
                default: _withCtx(() => [_createTextVNode(" 退款押金 ")]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          }, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
            "current-page": searchData.pageNo,
            "page-size": searchData.pageSize,
            background: "",
            layout: "prev, pager, next, jumper",
            total: total.value,
            "hide-on-single-page": true,
            onCurrentChange: handleCurrentChange
          }, null, 8, ["current-page", "page-size", "total"])])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_drawer, {
        modelValue: drawerDetails.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => drawerDetails.value = $event),
        title: "车辆整备详情",
        size: "50%"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [drawerDetails.value ? (_openBlock(), _createBlock(HostlingDetails, {
          key: 0,
          id: _unref(id)
        }, null, 8, ["id"])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};