import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-115cc14f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pageHead"
};
const _hoisted_2 = {
  class: "text-large font-600 mr-3"
};
const _hoisted_3 = {
  class: "flex items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_page_header = _resolveComponent("el-page-header");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_affix = _resolveComponent("el-affix");
  return _openBlock(), _createBlock(_component_el_affix, {
    offset: 15
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_page_header, {
      onBack: $options.goBack
    }, {
      content: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1)]),
      extra: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
      _: 3
    }, 8, ["onBack"]), _createVNode(_component_el_divider)])]),
    _: 3
  });
}