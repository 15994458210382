import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { onBeforeUnmount, ref, shallowRef, watchEffect } from "vue";
import { UPLOAD_FILE_URL } from "@/utils/config";
import store from "@/store";
import '@wangeditor/editor/dist/css/style.css';
export default {
  __name: 'editor',
  props: {
    html: String
  },
  emits: ["editorValueChang"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef();

    // 内容 HTML
    const valueHtml = ref(props.html);
    watchEffect(() => {
      valueHtml.value = props.html;
    });
    const toolbarConfig = {
      "toolbarKeys": ["headerSelect", "|", "bold", "underline", "italic", {
        "key": "group-more-style",
        "title": "更多",
        "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path></svg>",
        "menuKeys": ["through", "code", "sup", "sub", "clearStyle"]
      }, "color", "bgColor", "|", "fontSize", "fontFamily", "lineHeight", "|", "bulletedList", "numberedList", {
        "key": "group-justify",
        "title": "对齐",
        "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
        "menuKeys": ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"]
      }, {
        "key": "group-indent",
        "title": "缩进",
        "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
        "menuKeys": ["indent", "delIndent"]
      }, "|", "emotion", {
        "key": "group-image",
        "title": "图片",
        "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z\"></path></svg>",
        "menuKeys": ["insertImage", "uploadImage"]
      }, "insertTable", "divider", "|", "undo", "redo", "|", "fullScreen"],
      "excludeKeys": [],
      "insertKeys": {
        "index": 0,
        "keys": []
      },
      "modalAppendToBody": false
    };
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          server: UPLOAD_FILE_URL,
          headers: {
            Logintoken: store.getters.getToken
          },
          fieldName: 'file',
          // 这里有个坑，如果返回的响应结果是没有上传文件，跟这里关系很大
          customInsert(res, insertFn) {
            if (res.result === 0) {
              insertFn(res.data);
            }
          }
        }
      }
    };

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });
    const handleCreated = editor => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };
    const emit = __emit;
    const handleChange = () => {
      emit('editorValueChang', valueHtml.value);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(Toolbar), {
        defaultConfig: toolbarConfig,
        editor: editorRef.value,
        mode: "default",
        style: {
          "border-bottom": "1px solid #ccc"
        }
      }, null, 8, ["editor"]), _createVNode(_unref(Editor), {
        modelValue: valueHtml.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => valueHtml.value = $event),
        defaultConfig: editorConfig,
        mode: "default",
        style: {
          "height": "300px",
          "overflow-y": "hidden"
        },
        onOnCreated: handleCreated,
        onOnChange: handleChange
      }, null, 8, ["modelValue"])], 64);
    };
  }
};