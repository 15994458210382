import request from "@/utils/request";

/**
 * 获取车辆整备/押金列表
 * */
export function violationHostling(data) {
    return request({
        url: '/admin/order/tenance/search',
        method: 'post',
        data
    })
}

/**
 * 获取车辆整备详情
 * */
export function hostlingDetails(id) {
    return request({
        url: '/admin/order/tenance/detail',
        method: 'post',
        data: {id}
    })
}


/**
 * 获取车辆整备列表
 * */
export function refund(id, refundPrice) {
    return request({
        url: '/admin/order/tenance/violation/doRefund',
        method: 'post',
        data: {
            id,
            refundPrice
        }
    })
}

