import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-66be0e5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "overflow-container"
};
import { ref, watchEffect } from "vue";
import { hostlingDetails } from "@/api/violationHostling";
export default {
  __name: 'hostlingDetails',
  props: {
    id: String
  },
  setup(__props) {
    const props = __props;
    const formData = ref({});
    watchEffect(() => {
      if (props.id !== "") {
        hostlingDetails(props.id).then(res => {
          formData.value = res.data;
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData.value,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "订单ID",
          prop: "id"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.id = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "会员姓名",
          prop: "trueName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.trueName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.trueName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "mobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.mobile,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.mobile = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "证件号码",
          prop: "cardId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.cardId,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.cardId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "商品订单号",
          prop: "orderNumber"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.orderNumber,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.orderNumber = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "支付成功时间",
          prop: "strPaySuccessTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strPaySuccessTime,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.value.strPaySuccessTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "创建时间",
          prop: "strCreateTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strCreateTime,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.value.strCreateTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "支付状态",
          prop: "strPayStatus"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.strPayStatus,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formData.value.strPayStatus = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "门店名称",
          prop: "storeName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.storeName,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formData.value.storeName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "会员ID",
          prop: "memberId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.memberId,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formData.value.memberId = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "支付金额",
          prop: "payPrice"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.payPrice,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formData.value.payPrice = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};