import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-container"
};
const _hoisted_2 = {
  class: "card-header"
};
import { ref, watchEffect } from "vue";
import { userDetails } from "@/api/zx";
export default {
  __name: 'zxUserDetails',
  props: {
    id: String
  },
  setup(__props) {
    const props = __props;
    const formData = ref({});
    watchEffect(() => {
      if (props.id !== "") {
        userDetails(props.id).then(res => {
          formData.value = res.data;
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_card = _resolveComponent("el-card");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: formData.value,
        "label-position": "top",
        "label-width": "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "会员ID",
          prop: "id"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.id = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "phone"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.phone,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.phone = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证",
          prop: "idCard"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.idCard,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.idCard = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "用户姓名",
          prop: "knightName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.knightName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.knightName = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证正面",
          prop: "idCardFrontFile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            src: formData.value.idCardFrontFile
          }, null, 8, ["src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "身份证反面",
          prop: "idCardReverseFile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            src: formData.value.idCardReverseFile
          }, null, 8, ["src"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "注册时间",
          prop: "registryTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            disabled: "",
            modelValue: formData.value.registryTime,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.registryTime = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formData.value.contactsInfoList, (item, index) => {
          return _openBlock(), _createBlock(_component_el_card, {
            key: index,
            class: "box-card"
          }, {
            header: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, "紧急联系人" + _toDisplayString(index), 1)])]),
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "联系人姓名",
              prop: "name"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                disabled: "",
                modelValue: item.name,
                "onUpdate:modelValue": $event => item.name = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
              _: 2
            }, 1024), _createVNode(_component_el_form_item, {
              label: "联系人手机号",
              prop: "phone"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                disabled: "",
                modelValue: item.phone,
                "onUpdate:modelValue": $event => item.phone = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
              _: 2
            }, 1024), _createVNode(_component_el_form_item, {
              label: "关系",
              prop: "relation"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                disabled: "",
                modelValue: item.relation,
                "onUpdate:modelValue": $event => item.relation = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["model"])]);
    };
  }
};