export let storage = {
    //设置所储存的key和value
    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    //通过key获得value
    get(key) {
        return JSON.parse(sessionStorage.getItem(key))
    },
    //移除key对应的value
    remove(key) {
        sessionStorage.removeItem(key)
    },
    //移除所有暂存信息
    removeAll() {
        sessionStorage.clear()
    },
    //获得所有暂存信息
    getAll() {
        let len = sessionStorage.length
        // eslint-disable-next-line no-array-constructor
        let arr = []
        for (let i = 0; i < len; i++) {
            let getKey = sessionStorage.key(i)
            let getVal = sessionStorage.getItem(getKey)
            arr[i].push({
                'key': getKey,
                'val': getVal
            })
        }
        return arr
    }
}
