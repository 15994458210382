import request from "@/utils/request";

/**
 * 志忻订单列表
 * */
export function orderList(data) {
    return request({
        url: '/admin/zxRent/order/search',
        method: 'post',
        data
    })
}

/**
 * 志忻订单详情
 * */
export function orderDetails(id) {
    return request({
        url: '/admin/zxRent/order/detail',
        method: 'post',
        data: {id}
    })
}

/**
 * 志忻用户列表
 * */
export function userList(data) {
    return request({
        url: '/admin/zxRent/member/search',
        method: 'post',
        data
    })
}

/**
 * 志忻用户详情
 * */
export function userDetails(id) {
    return request({
        url: '/admin/zxRent/member/detail',
        method: 'post',
        data: {id}
    })
}


/**
 * 志忻取换退车列表
 * */
export function changeList(data) {
    return request({
        url: '/admin/zxRent/car/search',
        method: 'post',
        data
    })
}

/**
 * 志忻取换退车详情
 * */
export function changeDetails(id) {
    return request({
        url: '/admin/zxRent/car/detail',
        method: 'post',
        data: {id}
    })
}


/**
 * 志忻租期订单管理
 * */
export function leaseTermOrderList(data) {
    return request({
        url: '/admin/zxRent/deduction/search',
        method: 'post',
        data
    })
}

/**
 * 志忻租期订单详情
 * */
export function leaseTermOrderDetails(id) {
    return request({
        url: '/admin/zxRent/deduction/v1',
        method: 'post',
        data: {id}
    })
}


/**
 * 志忻租期管理
 * */
export function leaseTermList(data) {
    return request({
        url: '/admin/zxRent/deduction/detail/search',
        method: 'post',
        data
    })
}

/**
 * 志忻租期详情
 * */
export function leaseTermDetails(id) {
    return request({
        url: '/admin/zxRent/deduction/detail/v1',
        method: 'post',
        data: {id}
    })
}


/**
 * 志忻退车
 * */
export function zxReturnVehicle(id) {
    return request({
        url: '/admin/zxRent/order/doReturn',
        method: 'post',
        data: {id}
    })
}

/**
 * 换车
 * */
export function zxTransfer(data) {
    return request({
        url: '/admin/zxRent/order/doChange',
        method: 'post',
        data
    })
}


/**
 * 志忻审核取车审核
 * */
export function zxCarExamine(id, istatus) {
    return request({
        url: '/admin/zxRent/car/doAuthStatus',
        method: 'post',
        data: {
            id,
            istatus
        }
    })
}

/**
 * 志忻撤销已核销
 * */
export function zxCancelConfirm(id) {
    return request({
        url: '/admin/zxRent/car/doCancleConfirm',
        method: 'post',
        data: {
            id
        }
    })
}


