import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "user-search"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "myPagination"
};
import { Edit, Search } from "@element-plus/icons-vue";
import { ref, onBeforeMount } from 'vue';
import { useRouter } from "vue-router";
import PageHeaderComponents from "@/components/page-header.vue";
import { userList } from "@/api/user";
export default {
  __name: 'list',
  setup(__props) {
    const router = useRouter();
    const tableData = ref([]);
    const mobile = ref("");
    const trueName = ref("");
    const istatus = ref('');
    let total = ref(0);
    let pageNo = ref(1);
    let pageSize = ref(10);
    const search = () => {
      userList({
        "mobile": mobile.value,
        "trueName": trueName.value,
        "istatus": istatus.value,
        "pageNo": pageNo.value,
        "pageSize": pageSize.value
      }).then(res => {
        total.value = res.data.totalCount;
        tableData.value = res.data.systemUserInfoList;
      });
    };
    const openUpdate = id => {
      router.push({
        name: 'updateUser',
        state: {
          id
        }
      });
    };
    const handleCurrentChange = val => {
      pageNo.value = val;
      search();
    };
    onBeforeMount(() => {
      search();
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PageHeaderComponents, {
        label: "用户列表"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: trueName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => trueName.value = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "姓名"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_input, {
        modelValue: mobile.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => mobile.value = $event),
        "prefix-icon": _unref(Search),
        clearable: "",
        placeholder: "手机号码"
      }, null, 8, ["modelValue", "prefix-icon"]), _createVNode(_component_el_select, {
        modelValue: istatus.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => istatus.value = $event),
        placeholder: "状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          key: "",
          label: "状态",
          value: ""
        }), (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          label: "启用",
          value: 0
        })), (_openBlock(), _createBlock(_component_el_option, {
          key: 1,
          label: "禁用",
          value: 1
        }))]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_button, {
        onClick: search,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      }), _createVNode(_component_el_button, {
        plain: "",
        type: "warning",
        onClick: _cache[3] || (_cache[3] = $event => openUpdate(0))
      }, {
        default: _withCtx(() => [_createTextVNode("添加")]),
        _: 1
      })]), _createVNode(_component_el_divider), _createVNode(_component_el_table, {
        data: tableData.value
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "姓名",
          prop: "trueName"
        }), _createVNode(_component_el_table_column, {
          label: "手机号",
          prop: "mobile"
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          prop: "istatus"
        }, {
          default: _withCtx(scope => [scope.row.istatus === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_2, "启用")) : scope.row.istatus === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_3, "禁用")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "id"
        }, {
          default: _withCtx(data => [_createVNode(_component_el_button, {
            icon: _unref(Edit),
            onClick: $event => openUpdate(data.row.id),
            plain: "",
            size: "small",
            type: "primary"
          }, null, 8, ["icon", "onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
        "current-page": _unref(pageNo),
        "page-size": _unref(pageSize),
        background: "",
        layout: "prev, pager, next, jumper",
        total: _unref(total),
        "hide-on-single-page": true,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "total"])])], 64);
    };
  }
};