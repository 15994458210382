import router from "@/router/index";
import store from '@/store'

//动态添加路由
export function generateRoutes() {
    const _asyncRoutes = store.getters.getMenuList
    if (_asyncRoutes == null)
        return
    _asyncRoutes.forEach(menu => {
        const mRouter = menuToRoute(menu)
        router.addRoute("layout", mRouter)
    })
}

//将菜单转换成router可以识别的路由
const menuToRoute = (menu) => {
    if (!menu.component) {
        return null;
    } else {
        let route = {
            name: menu.name,
            path: menu.path,
            children: [],
            displayName: menu.title,
            meta: {
                title: menu.title,

            }
        }
        if (menu.children && menu.children.length > 0) {
            menu.children.forEach(item => {
                let childrenRouter = {
                    name: item.name,
                    path: item.path,
                    component: () => Promise.resolve(require('@/views/' + item.component + ".vue")),
                    displayName: item.title,
                    meta: {
                        title: item.title,

                    }
                }
                route.children.push(childrenRouter);
            })
        }
        route.component = () => Promise.resolve(require('@/views/' + menu.component + ".vue"));

        return route;
    }
}

export function asyncRouter() {
    const menu = [
        {
            path: 'user',
            name: "user",
            component: "user/list",
            iconName: "User",
            title: "用户管理",
        },
        {
            path: 'user/update',
            name: "updateUser",
            component: "user/update",
            iconName: "User",
            title: "用户添加",
            hide: true
        },
        {
            path: 'shop',
            name: "shop",
            component: "shop/index",
            iconName: "TakeawayBox",
            title: "门店管理",
        },
        {
            path: 'shop/update',
            name: "updateShop",
            component: "shop/update",
            title: "添加",
            hide: true,
        },
        {
            path: 'vehicle',
            name: "vehicle",
            component: "vehicle/index",
            iconName: "Bicycle",
            title: "车辆管理",
            children: [
                {
                    path: 'vehicleType',
                    name: "vehicleType",
                    component: 'vehicle/type/vehicleType',
                    title: "车型表"

                },
                {
                    path: 'vehicleList',
                    name: "vehicleList",
                    component: 'vehicle/list/vehicleList',
                    title: "车辆表",
                },
                {
                    path: 'vehicleList/update',
                    name: "vehicleUpdate",
                    component: 'vehicle/list/update',
                    title: "添加车辆",
                    hide: true,
                },
                {
                    path: 'vehicleType/update',
                    name: "vehicleTypeUpdate",
                    component: 'vehicle/type/update',
                    title: "添加车型",
                    hide: true,
                }
            ]
        },
        {
            path: 'goods',
            name: "goods",
            component: 'goods/list',
            iconName: "Orange",
            title: "商品管理",
        },
        {
            path: 'goods/update',
            name: "updateGoods",
            component: 'goods/update',
            title: "添加商品",
            hide: true
        },
        {
            path: 'member',
            name: "member",
            component: "member/index",
            iconName: "GoldMedal",
            title: "会员管理",
        },
        {
            path: 'member/update',
            name: "updateMember",
            component: 'member/update',
            title: "添加商品",
            hide: true
        },
        {
            path: 'violationHostling',
            name: "violationHostling",
            title: "违章/整备",
            iconName: "Help",
            component: "violationHostling/index",
        },
        {
            path: 'order',
            name: "order",
            title: "订单管理",
            iconName: "Tickets",
            component: "order/index",
            children: [
                {
                    path: 'goodsOrder',
                    name: "goodsOrder",
                    component: "order/page/goodsOrder",
                    title: "商品订单表",
                },
                {
                    path: 'carRentalOrder',
                    name: "carRentalOrder",
                    component: "order/page/carRentalOrder",
                    title: "租车订单表",
                }
            ]
        },
        {
            path: 'zx',
            name: "zx",
            title: "志忻管理",
            iconName: "HelpFilled",
            component: "zx/index",
            children: [
                {
                    path: 'zxOrder',
                    name: "zxOrder",
                    component: "zx/page/zxOrder",
                    title: "订单管理",
                },
                {
                    path: 'zxLeaseTermOrder',
                    name: "zxLeaseTermOrder",
                    component: "zx/page/zxLeaseTermOrder",
                    title: "租期订单管理",
                },
                {
                    path: 'zxLeaseTerm',
                    name: "zxLeaseTerm",
                    component: "zx/page/zxLeaseTerm",
                    title: "租期管理",
                },
                {
                    path: 'zxUser',
                    name: "zxUser",
                    component: "zx/page/zxUser",
                    title: "用户管理",
                },
                {
                    path: 'zxChange',
                    name: "zxChange",
                    component: "zx/page/zxChange",
                    title: "取车核销管理",
                }
            ]
        },
    ]


    store.commit('setMenuList', menu);
    generateRoutes()
}