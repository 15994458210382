import request from "@/utils/request";

/**
 * 门店列表
 * */
export function shopList(data) {
    return request({
        url: '/admin/store/search',
        method: 'post',
        data
    })
}

/**
 * 下拉框门店列表
 * */
export function shopSelect(storeName) {
    return request({
        url: '/admin/store/searchByName',
        method: 'post',
        loading: false,
        data: {
            storeName
        }
    })
}

/**
 * 添加
 * */
export function addShop(data) {
    return request({
        url: '/admin/store/doAdd',
        method: 'post',
        data
    })
}

/**
 * 更新
 * */
export function updateShop(data) {
    return request({
        url: '/admin/store/doUpdate',
        method: 'post',
        data
    })
}

/**
 * 详情
 * */
export function detail(id) {
    return request({
        url: '/admin/store/detail',
        method: 'post',
        data: {id}
    })
}





